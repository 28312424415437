import Link from 'next/link';

export default function CtaBox(props) {

    return (
        <section className='cta-box'>
            {props.style === 'angled-blue' && (
                <div className="relative">
                    <div className="skew-top"></div>
                    <div className='relative bg-gradient-trust'>
                        <div className="container py-32">
                            <Content {...props} />
                        </div>
                    </div>
                    <div className="skew-bottom"></div>
                </div>
            )}
            {props.style !== 'angled-blue' && (
                <div className={`flex flex-col w-full max-w-screen-xl rounded-[0.25rem] mx-auto cta-box ${props.style}`}>
                    <Content {...props} />
                </div>
            )}
        </section>
    );
}

const Content = (props) => {
    return (
        <div className={`flex flex-col items-center justify-center px-4 lg:px-24 py-16 text-center`}>
            <span
                dangerouslySetInnerHTML={{ __html: props.content }}
            ></span>

            {props.buttons && props.buttons.map((button) => (
                <Link key={`cta-button-${button.id}`}
                    href={button.link}
                >
                    <a
                        title={button.label}
                        className={`btn mt-4 btn-lg ${button.style}`}
                    >
                        {button.label}
                    </a>
                </Link>
            ))}
        </div>
    )
}